$(document).ready(function() {

  // FastClick init
  $(function() {
    FastClick.attach(document.body);
  });

  // Mobile nav
  $(function() {
    $('.mobile-nav-btn').click(function() {
      toggleNav();
    });
  });

  function toggleNav() {
    if ($('#site-wrapper').hasClass('show-nav')) {
      $('#site-wrapper').removeClass('show-nav');
    } else {
      $('#site-wrapper').addClass('show-nav');
    }
  }

  $(document).keyup(function(e) {
    if (e.keyCode == 27) {
      if ($('#site-wrapper').hasClass('show-nav')) {
        toggleNav();
      }
    }
  });

});
